import create from "zustand";
import { persist } from "zustand/middleware";
import { axiosLogin, ILoginData } from "../services/axios";

interface IAuthState {
  token: string;
}

interface IAuthDispatch {
  login: (userData: ILoginData) => Promise<void>;
  logout: () => void;
}

type AuthStoreType = IAuthState & IAuthDispatch;

export const useAuthStore = create(
  persist<AuthStoreType>(
    (set) => ({
      token: "",
      login: (userData: ILoginData) =>
        new Promise<void>(async (resolve, reject) => {
          try {
            const res = await axiosLogin(userData);
            const token = res.data.data.token;
            set({ token });
            resolve();
          } catch (error) {
            reject(error);
          }
        }),
      logout: () => {
        set({ token: "" });
      },
    }),
    { name: "auth-device-storage" }
  )
);
