import { domAnimation, LazyMotion, m } from "framer-motion";
import { ReactNode } from "react";

const transitionVariant = {
  hidden: { opacity: 0, x: -200 },
  enter: { opacity: 1, x: 0 },
};

export const CardContainer = ({ children }: { children: ReactNode }) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        variants={transitionVariant}
        initial="hidden"
        animate="enter"
        transition={{ type: "tween" }}
        className="bg-white mx-auto rounded-xl shadow-xl max-w-xl w-full px-8 py-8"
      >
        {children}
      </m.div>
    </LazyMotion>
  );
};
