import { forwardRef } from "react";

const Input = forwardRef<HTMLInputElement, JSX.IntrinsicElements["input"]>(
  (props, ref) => (
    <input
      autoComplete="off"
      className="outline-none border-b-2 text-wline-dark focus:border-wline transition-all py-1 bg-transparent rounded-none"
      ref={ref}
      {...props}
    />
  )
);

export default Input;
