import { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { RequireAuth } from "./HOC/RequireAuth";
import DeviceAuth from "./routes/DeviceAuth";
import NotFound from "./routes/NotFound";
import UserConfirm from "./routes/UserConfirm";
import UserSignup from "./routes/UserSignup";

function App() {
  return (
    <>
      <Header />
      <main
        className="flex bg-wline justify-center items-center px-3 py-4 min-h-780px"
        style={{ height: "calc(100vh - 160px)" }}
      >
        <Routes>
          <Route path="/" element={<DeviceAuth />} />
          <Route
            path="/signup"
            element={
              <RequireAuth>
                <UserSignup />
              </RequireAuth>
            }
          />
          <Route
            path="/signup-success/:id"
            element={
              <RequireAuth>
                <UserConfirm />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;

const Header = () => {
  return (
    <div className="bg-white w-full h-20 flex justify-center items-center">
      <div className="w-64 h-7">
        <img
          className="h-full w-full object-contain"
          src="/assets/wl-logo-extended.jpg"
        />
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="w-full h-20 bg-slate-900 flex gap-2 justify-center items-center text-xs font-semibold text-white text-center">
      <span>{"©Worldline"}</span>{" "}
      <Link to={"/"} className="underline border-l pl-2 border-white">
        {"Autenticazione"}
      </Link>
      <a
        className="underline border-l px-2 border-white"
        href="/documents/Informativa_Worldline.pdf"
      >
        {"Privacy"}
      </a>
    </div>
  );
};
