import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/auth";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const token = useAuthStore((state) => state.token);

  if (!token) {
    return <Navigate to={"/"} replace />;
  }

  return children;
}
