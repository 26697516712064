const CardHeader = ({
  title = "",
  logo = true,
}: {
  title: string;
  logo?: boolean;
}) => {
  return (
    <div className="w-full flex items-center gap-3">
      {logo && (
        <div className=" w-16 sm:w-20 h-20 flex-shrink-0">
          <img
            className="w-full h-full object-contain"
            src="/assets/wl-logo.png"
          />
        </div>
      )}
      <div
        className={
          (logo ? "border-l-2" : "") +
          " " +
          "border-wline h-16 flex items-center justify-center w-full pl-1.5"
        }
      >
        <h1 className="text-3xl sm:text-4xl font-semibold w-full text-center text-wline">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default CardHeader;
