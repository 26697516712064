import { useNavigate } from "react-router-dom";
import CardHeader from "../components/CardHeader";
import { CardContainer } from "../components/layout/CardContainer";

const UserConfirm = () => {
  const navigate = useNavigate();

  return (
    <CardContainer>
      <CardHeader title="Registrazione effettuata" />
      <h2 className="text-slate-700 text-center text-xl mt-8">
        {
          "La registrazione è avvenuta con successo, verrà inviata un'email all'indirizzo indicato"
        }
      </h2>
      <p className="mt-8 text-2xl sm:text-3xl text-center">{"Grazie per la registrazione"}</p>
      <div className="mt-8 flex w-full justify-center">
        <button
          className="select-none self-center disabled:opacity-50 disabled:pointer-events-none text-white font-semibold bg-wline active:bg-wline-dark py-2 px-3 rounded-md"
          type="button"
          onClick={() => navigate("/signup")}
        >
          {"Nuova registrazione"}
        </button>
      </div>
    </CardContainer>
  );
};

export default UserConfirm;
