import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import CardHeader from "../components/CardHeader";
import Input from "../components/Input";
import { CardContainer } from "../components/layout/CardContainer";
import { useAuthStore } from "../store/auth";

interface IAuthInput {
  username: string;
  password: string;
}

const DeviceAuth = () => {
  const token = useAuthStore((state) => state.token);

  return (
    <CardContainer>
      <CardHeader title={"Autenticazione sistema"} />
      {!token && <DeviceForm />}
      {token && <DeviceLoggedIn />}
    </CardContainer>
  );
};

export default DeviceAuth;

const DeviceForm = () => {
  const { register, handleSubmit, reset, formState, setError } =
    useForm<IAuthInput>({
      mode: "onChange",
    });
  const onSubmit: SubmitHandler<IAuthInput> = async (data) =>
    await useAuthStore
      .getState()
      .login(data)
      .catch((err) => {
        setError("password", {
          type: "value",
          message: "Errore in fase di login",
        });
      });

  return (
    <form
      autoComplete="off"
      className="flex flex-col mt-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <label htmlFor="username">Username</label>
      <Input
        readOnly={formState.isSubmitting}
        placeholder="Inserire ID"
        type="text"
        {...register("username", { required: true })}
      />
      <label className="mt-4">Password</label>
      <Input
        readOnly={formState.isSubmitting}
        type="password"
        placeholder="Inserire password"
        {...register("password", { required: true })}
      />
      {formState.errors.password && (
        <p className="text-sm text-red-500 mt-4">
          {formState.errors.password.message}
        </p>
      )}
      <div className="mt-8 flex w-full justify-center">
        <button
          className="w-36 self-center disabled:opacity-50 disabled:pointer-events-none text-white font-semibold bg-wline active:bg-wline-dark py-2 px-3 rounded-md"
          type="submit"
          disabled={!formState.isValid || formState.isSubmitting}
        >
          {"Login"}
        </button>
      </div>
    </form>
  );
};

const DeviceLoggedIn = () => {
  return (
    <>
      <div className="w-full text-center">
        <h2 className="mt-4 text-3xl border-2 w-fit mx-auto px-4 text-slate-700 py-2 rounded-md border-wline">
          Dispositivo autenticato
        </h2>
      </div>
      <p className="text-2xl mt-4 text-center sm:px-8 text-gray-500">
        {"E' possibile procedere per la "}
        <span className="font-semibold text-slate-700">registrazione</span>{" "}
        {"oppure effettuare il "}
        <span className="text-slate-700 font-semibold">logout</span>
      </p>
      <div className="mt-8 flex w-full justify-between">
        <button
          className="text-sm w-24 text-blue-500 active:text-blue-800 border py-2 px-3 rounded-md"
          type="button"
          onClick={() => {
            useAuthStore.getState().logout();
          }}
        >
          Esci
        </button>
        <Link
          to={"/signup"}
          className="ml-4 w-36 text-center disabled:opacity-50 disabled:pointer-events-none text-white font-semibold bg-wline active:bg-wline-dark py-2 px-3 rounded-md"
        >
          Avanti
        </Link>
        <div className="w-24 hidden sm:block h-full bg-blue-300" />
      </div>
    </>
  );
};
