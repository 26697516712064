import axios from "axios";
import { API_BASE_URL } from "../config";
import { useAuthStore } from "../store/auth";

export const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

axiosClient.interceptors.request.use((config) => {
  const token = useAuthStore.getState().token;
  if (!token) return config;
  config.headers = { ...config.headers, Authorization: "Basic " + token };
  return config;
});

export const axiosLogin = (userData: ILoginData) => {
  return axios.post(API_BASE_URL + "/login", userData);
};

export const axiosCreateLead = (lead: ILead) => {
  return axiosClient.post("/leads", lead);
};

export interface ILoginData {
  username: string;
  password: string;
}

export interface ILead {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  professional_field: string;
}
