import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CardHeader from "../components/CardHeader";
import Input from "../components/Input";
import { CardContainer } from "../components/layout/CardContainer";
import { axiosCreateLead } from "../services/axios";
interface IUserInput {
  first_name: string;
  last_name: string;
  professional_field: string;
  country: string;
  phone_number: string;
  email: string;
  notes: string;
}

const UserSignup = () => {
  const { register, handleSubmit, reset, formState } = useForm<IUserInput>({
    mode: "onChange",
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IUserInput> = async (data) => {
    return await axiosCreateLead(data)
      .then((res) => {
        reset();
        const id = res.data.data?.ticket_val ?? "not-found";
        navigate("/signup-success/" + id);
      })
      .catch((err) => {
        setPostError(
          "Errore in fase di registrazione, prova a correggere alcuni campi o ad effettuare di nuovo l'autenticazione del dispositivo"
        );
      });
  };

  const [postError, setPostError] = useState<string>("");

  return (
    <CardContainer>
      <CardHeader title="Registrazione utente" />
      {postError && <p className="text-xs text-red-500">{postError}</p>}
      <form
        autoComplete="off"
        className="flex flex-col mt-4 text-sm"
        onSubmit={handleSubmit(onSubmit)}
        onFocus={() => {
          setPostError("");
        }}
      >
        <label htmlFor="first_name">Nome</label>
        <Input
          readOnly={formState.isSubmitting}
          placeholder="Inserisci il tuo nome"
          type="text"
          {...register("first_name", { required: true })}
        />
        <label className="mt-4">Cognome</label>
        <Input
          readOnly={formState.isSubmitting}
          type="text"
          placeholder="Inserisci il tuo cognome"
          {...register("last_name", { required: true })}
        />
        <label className="mt-4">Azienda</label>
        <Input
          readOnly={formState.isSubmitting}
          type="text"
          placeholder="Di quale settore di occupi?"
          {...register("professional_field", { required: true })}
        />
        <label className="mt-4">Regione</label>
        <Input
          readOnly={formState.isSubmitting}
          type="text"
          placeholder="Regione di provenienza"
          {...register("country", { required: true })}
        />
        <label className="mt-4">Numero di telefono</label>
        <Input
          readOnly={formState.isSubmitting}
          type="text"
          placeholder="Il tuo numero di telefono"
          {...register("phone_number", { required: true })}
        />
        <label className="mt-4">Email</label>
        <Input
          readOnly={formState.isSubmitting}
          type="email"
          placeholder="La tua mail"
          {...register("email", { required: true })}
        />
        <label className="mt-4">Note</label>
        <Input
          readOnly={formState.isSubmitting}
          type="text"
          placeholder="Note aggiuntive"
          {...register("notes", { required: false })}
        />
        <p className="text-xs mt-4 italic">
          {`Prendi visione dell'`}
          <a
            href={"/documents/Informativa_Salone_dei_Pagamenti.pdf"}
            className="text-blue-500 font-semibold cursor-pointer"
            target="_blank"
          >
            {"informativa privacy"}
          </a>
          {". "}
          {`Cliccando su “Voglio essere ricontattato” presterai il consenso al trattamento dei tuoi dati per fini di Marketing.`}
        </p>
        <div className="mt-4 flex w-full justify-between">
          <button
            disabled={formState.isSubmitting}
            className="text-sm w-24 text-blue-500 disabled:opacity-50 disabled:pointer-events-none active:text-blue-800 border py-2 px-3 rounded-md"
            type="button"
            onClick={() => {
              reset();
              setPostError("");
            }}
          >
            Reimposta
          </button>
          <button
            className="ml-4 w-36 self-center disabled:opacity-50 disabled:pointer-events-none text-white font-semibold bg-wline active:bg-wline-dark py-2 px-3 rounded-md text-sm"
            type="submit"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            Voglio essere ricontattato
          </button>
          <div className="w-24 hidden sm:block h-full bg-blue-300" />
        </div>
      </form>
    </CardContainer>
  );
};

export default UserSignup;
